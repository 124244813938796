.main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: #fff;
}
.main-body {
  padding: 106px 24px 0 24px;
}
